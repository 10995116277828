<template>
  <section>
    <h1>User Offered</h1>
    <v-simple-table class="tableborder mt-3">
      <thead>
        <tr>
          <th class="text-left">White Label</th>
          <th class="text-left">Firstname</th>
          <th class="text-left">Lastname</th>
          <th class="text-left">Message</th>
          <th class="text-left">Email</th>
          <th class="text-left">Phone</th>
          <th class="text-left">Link</th>
        </tr>
      </thead>
      <tbody v-if="!loading">
        <tr v-for="(data, index) in offers.results" :key="index + 'offer'">
          <td>
            {{ data.whitelabel.name }}
          </td>
          <td>
            {{ data.firstName }}
          </td>
          <td>
            {{ data.lastName }}
          </td>
          <td>
            {{ data.email }}
          </td>
          <td>
            {{ data.phone }}
          </td>
          <td>
            {{ data.offer }}
          </td>
          <td>
            <router-link
              :to="`/dashboard/publishedproperty/` + data.connectedTo"
              target="_blank"
              rel="noopener noreferrer"
              >Check</router-link
            >
          </td>
        </tr>
      </tbody>
      <tbody v-if="loading">
        <tr>
          <td colspan="5" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Offers",
  data() {
    return {
      loading: true,
      offers: [],
      page: 1,
      limit: 20,
    };
  },
  methods: {
    ...mapActions(["getOffers"]),
  },
  computed: {
    ...mapGetters(["allOffers"]),
  },
  async created() {
    await this.getOffers({
      page: this.page,
      limit: this.limit,
    });
    this.offers = this.allOffers;
    this.loading = false;
  },
};
</script>

<style>
</style>